class Invoice {
  constructor(stripeInvoice) {
    this.id = stripeInvoice.id
    this.stripe = stripeInvoice
  }
  get chargeRefunded() {
    return this.stripe.charge && this.stripe.charge.refunded
  }
  get amountDue() {
    return +this.stripe.amount_due
  }
  get amountPaid() {
    return +this.stripe.amount_paid
  }
  get amountRemaining() {
    return +this.stripe.amount_remaining
  }
  get billing() {
    return this.stripe.billing
  }
  get billingReason() {
    return this.stripe.billing_reason
  }
  get collectionMethod() {
    return this.stripe.collection_method
  }
  get customerId() {
    return this.stripe.customer
  }
  get date() {
    return new Date(this.stripe.date * 1000)
  }
  get endingBalance() {
    return this.stripe.ending_balance
  }
  get startingBalance() {
    return this.stripe.starting_balance
  }
  get url() {
    return this.stripe.hosted_invoice_url
  }
  get pdf() {
    return this.stripe.invoice_pdf
  }
  get lines() {
    return this.stripe.lines
  }
  get firstLine() {
    if (this.lines && this.lines.data) {
      return this.lines.data[0]
    }
    return null
  }
  get description() {
    if (this.firstLine) {
      return this.firstLine.description
    }
    return ''
  }
  get number() {
    return this.stripe.number
  }
  get isPaid() {
    return this.stripe.paid
  }
  get periodStart() {
    return new Date(this.stripe.period_start * 1000)
  }
  get periodEnd() {
    return new Date(this.stripe.period_end * 1000)
  }
  get subscriptionId() {
    return this.stripe.subscription.metadata.id
  }
  get stripeSubscription() {
    return this.stripe.subscription
  }
  get start() {
    return this.stripe.start
  }
  get status() {
    return this.stripe.status
  }
}

export default Invoice
