import { timeDay } from 'd3'
import Plan from './Plan'

class Subscription {
  constructor(dbSubscription, stripeSubscription) {
    this.id = dbSubscription.id
    this.stripe = stripeSubscription
    this.plan = new Plan(dbSubscription.plan, stripeSubscription.plan)
    this.owner = dbSubscription.owner
  }
  get status() {
    return this.stripe.status
  }
  get cancelAtPeriodEnd() {
    return this.stripe.cancel_at_period_end
  }
  get canCancel() {
    return !(this.stripe.cancel_at_period_end || this.stripe.status === 'canceled')
  }
  get created() {
    return new Date(this.stripe.created * 1000)
  }
  get currentPeriodStart() {
    return new Date(this.stripe.current_period_start * 1000)
  }
  get currentPeriodEnd() {
    return new Date(this.stripe.current_period_end * 1000)
  }
  get nextChargeDate() {
    if (this.stripe.cancel_at_period_end) {
      return null
    }
    return new Date(this.stripe.current_period_end * 1000)
  }
  get endedAt() {
    return this.stripe.ended_at && new Date(this.stripe.ended_at * 1000)
  }
  get trialStart() {
    return this.stripe.trial_start && new Date(this.stripe.trial_start * 1000)
  }
  get trialEnd() {
    return this.stripe.trial_end && new Date(this.stripe.trial_end * 1000)
  }
  get trialDaysRemaining() {
    return timeDay.count(new Date(), new Date(this.stripe.trial_end * 1000))
  }
  get billingCycleAnchor() {
    return new Date(this.stripe.billing_cycle_anchor * 1000)
  }
  get cancelAt() {
    return this.stripe.cancel_at && new Date(this.stripe.cancel_at * 1000)
  }
  get canceledAt() {
    return this.stripe.canceled_at && new Date(this.stripe.canceled_at * 1000)
  }
}

export default Subscription
