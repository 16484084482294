<template>
  <div>
    <div v-if="invoices">
      <div v-if="invoices.length">
        <el-table :data="invoices" :default-sort="{prop: 'date', order: 'descending'}">
          <el-table-column label="Description" prop="description"></el-table-column>
          <el-table-column label="Date" prop="description" :formatter="formatDate"></el-table-column>
          <el-table-column label="Status" prop="status" sortable>
            <template slot-scope="scope">
              <invoice-status :invoice="scope.row"></invoice-status>
            </template>
          </el-table-column>
          <el-table-column prop="amountDue" label="Amount" :formatter="formatAmount" sortable></el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <a :href="scope.row.url" target="_blank">
                <el-button type="text">View Invoice</el-button>
              </a>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <div
                v-if="scope.row.stripeSubscription && scope.row.stripeSubscription.status === 'active'"
              >
                <router-link :to="`/subscription/${scope.row.subscriptionId}`">
                  <el-button type="text">Go to Subscription</el-button>
                </router-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>No Invoices</div>
    </div>
  </div>
</template>

<script>
import { timeFormat } from 'd3'
import Invoice from '@/models/Invoice'
import { getStripeInvoices } from '@/api/stripe.service'
import InvoiceStatus from '@/components/InvoiceStatus.vue'

const dateTimeFormat = timeFormat('%_d %b %Y')

export default {
  name: 'CustomerInvoices',
  components: {
    InvoiceStatus,
  },
  data() {
    return {
      invoices: null,
    }
  },
  async created() {
    try {
      const res = await getStripeInvoices()
      this.invoices = res.data.data.map((d) => new Invoice(d))
      this.$emit('invoices', this.invoices)
    } catch (e) {
      console.log('Unable to fetch invoices')
    }
  },
  methods: {
    formatAmount(row) {
      const monetaryString = `$${(row.amountDue / 100).toFixed(2)}`
      return monetaryString
    },
    formatDate(row) {
      return dateTimeFormat(row.date)
    },
  },
}
</script>