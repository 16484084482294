<template>
  <div>
    <!-- draft, open, paid, uncollectible, or void -->
    <el-tag v-if="invoice.status === 'paid' && !invoice.chargeRefunded" type="success">Paid</el-tag>
    <el-tag v-else-if="invoice.status === 'paid'">Refunded</el-tag>
    <el-tag v-else-if="invoice.status === 'void'" type="info">Void</el-tag>
    <el-tag v-else-if="invoice.status === 'uncollectible'" type="info">Uncollectible</el-tag>
    <el-tag v-else-if="invoice.status === 'open'">Open</el-tag>
  </div>
</template>

<script>
export default {
  name: 'InvoiceStatus',
  props: ['invoice'],
}
</script>
