<template>
<div>
  <el-tag v-if="subscription.status === 'active' && !subscription.cancelAtPeriodEnd" type="success">
    Active
  </el-tag>
  <el-tag v-if="subscription.status === 'active' && subscription.cancelAtPeriodEnd" type="info">
    Cancels on {{ subscription.currentPeriodEnd | formatDate }}
  </el-tag>
  <el-tag v-if="subscription.status === 'trialing'">
    Trial ends in {{ subscription.trialDaysRemaining }}
    {{ subscription.trialDaysRemaining === 1 ? 'day' : 'days' }}
  </el-tag>
  <el-tag v-if="subscription.status === 'canceled'" type="info">Canceled</el-tag>
  <el-tag v-if="subscription.status === 'unpaid'" type="danger">Unpaid</el-tag>
  <el-tag v-if="subscription.status === 'past_due'" type="warning">Past Due</el-tag>
  <el-tag v-if="subscription.status === 'incomplete'" type="danger">Incomplete</el-tag>
  <el-tag v-if="subscription.status === 'incomplete_expired'" type="danger">Incomplete Expired</el-tag>
</div>
</template>

<script>
import { timeFormat } from 'd3'

const dateTimeFormat = timeFormat('%_d %b %Y')
export default {
  name: 'SubscriptionStatus',
  props: ['subscription'],
  filters: {
    formatDate(value) {
      return dateTimeFormat(value)
    }
  },
}
</script>
