import axios from 'axios'
import { Auth } from 'aws-amplify'

const backendEnv = process.env.VUE_APP_BACKEND_ENV || process.env.NODE_ENV || 'development'
const isProd = backendEnv === 'production'
const devStripeEnv = 'live' // which stripe env to use while in local dev (live or test)
const stripeEnv = process.env.NODE_ENV === 'production' ? 'live' : devStripeEnv
let RESOURCE
if (isProd) {
  RESOURCE = 'https://api.floom.app/stripe'
} else if (stripeEnv === 'live') {
  RESOURCE = 'https://api-dev.floom.app/stripe'
} else {
  RESOURCE = 'https://1fe0zodci9.execute-api.us-east-1.amazonaws.com/dev-test/stripe'
}

// CUSTOMERS

export const createStripeCustomer = async ({ token }) => {
  const session = await Auth.currentSession()
  const headers = {
    Authorization: session.idToken.jwtToken,
  }
  return axios.post(
    `${RESOURCE}/customers`,
    {
      token,
    },
    {
      headers,
    }
  )
}

export const getStripeCustomer = async () => {
  const session = await Auth.currentSession()
  const headers = {
    Authorization: session.idToken.jwtToken,
  }
  return axios.get(`${RESOURCE}/customers`, {
    headers,
  })
}

export const updateStripeCustomer = async ({ token, name, address }) => {
  const session = await Auth.currentSession()
  const headers = {
    Authorization: session.idToken.jwtToken,
  }
  return axios.put(
    `${RESOURCE}/customers`,
    {
      token,
      name,
      address,
    },
    {
      headers,
    }
  )
}

// SUBSCRIPTIONS

export const createStripeSubscription = async ({ planId }) => {
  const session = await Auth.currentSession()
  const headers = {
    Authorization: session.idToken.jwtToken,
  }
  return axios.post(
    `${RESOURCE}/subscriptions`,
    {
      planId,
    },
    {
      headers,
    }
  )
}

export const getStripeSubscription = async (id) => {
  const session = await Auth.currentSession()
  const headers = {
    Authorization: session.idToken.jwtToken,
  }
  return axios.get(`${RESOURCE}/subscriptions/${id}`, {
    headers,
  })
}

export const getStripeSubscriptions = async () => {
  const session = await Auth.currentSession()
  const headers = {
    Authorization: session.idToken.jwtToken,
  }
  return axios.get(`${RESOURCE}/subscriptions`, {
    headers,
  })
}

export const updateStripeSubscription = async (id, update) => {
  const session = await Auth.currentSession()
  const headers = {
    Authorization: session.idToken.jwtToken,
  }
  return axios.put(`${RESOURCE}/subscriptions/${id}`, update, {
    headers,
  })
}

export const changeStripeSubscriptionPlan = async (id, planId) => {
  const session = await Auth.currentSession()
  const headers = {
    Authorization: session.idToken.jwtToken,
  }
  return axios.put(
    `${RESOURCE}/subscriptions/${id}`,
    {
      update: {
        newPlanId: planId,
      },
    },
    {
      headers,
    }
  )
}

export const cancelStripeSubscriptionAtPeriodEnd = async (id) => {
  const session = await Auth.currentSession()
  const headers = {
    Authorization: session.idToken.jwtToken,
  }
  return axios.put(
    `${RESOURCE}/subscriptions/${id}`,
    {
      update: {
        cancelAtPeriodEnd: true,
      },
    },
    {
      headers,
    }
  )
}

// PLANS

export const createStripePlan = async (serviceId, { amount, trialPeriodDays, name, description, allowance }) => {
  const session = await Auth.currentSession()
  const headers = {
    Authorization: session.idToken.jwtToken,
  }
  return axios.post(
    `${RESOURCE}/plans`,
    {
      serviceId,
      plan: {
        amount,
        trial_period_days: trialPeriodDays,
        name,
        description,
        allowance,
      },
    },
    {
      headers,
    }
  )
}

export const getStripePlan = (id) => axios.get(`${RESOURCE}/plans/${id}`)

export const updateStripePlan = async (id, update) => {
  const session = await Auth.currentSession()
  const headers = {
    Authorization: session.idToken.jwtToken,
  }
  return axios.put(`${RESOURCE}/plans/${id}`, update, {
    headers,
  })
}

// INVOICES

export const getStripeInvoices = async () => {
  const session = await Auth.currentSession()
  const headers = {
    Authorization: session.idToken.jwtToken,
  }
  return axios.get(`${RESOURCE}/invoices`, {
    headers,
  })
}

// PRODUCTS

export const createStripeProduct = async (serviceId) => {
  const session = await Auth.currentSession()
  const headers = {
    Authorization: session.idToken.jwtToken,
  }
  return axios.post(
    `${RESOURCE}/products`,
    {
      serviceId,
    },
    {
      headers,
    }
  )
}

export const getStripeProduct = (id) => axios.get(`${RESOURCE}/products/${id}`)

// VENDOR
export const getVendorStripeSubscriptions = async () => {
  const session = await Auth.currentSession()
  const headers = {
    Authorization: session.idToken.jwtToken,
  }
  return axios.get(`${RESOURCE}/vendor/subscriptions`, {
    headers,
  })
}
