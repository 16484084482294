<template>
  <div>
    <div v-if="!loading">
      <div v-if="subscriptions && subscriptions.length > 0">
        <el-table :data="subscriptions" :default-sort="{prop: 'date', order: 'descending'}">
          <el-table-column :formatter="formatName" label="Service"></el-table-column>
          <el-table-column label="Status" prop="status" sortable>
            <template slot-scope="scope">
              <subscription-status :subscription="scope.row"></subscription-status>
            </template>
          </el-table-column>
          <el-table-column prop="amount" label="Amount" :formatter="formatAmount" sortable></el-table-column>
          <el-table-column
            prop="nextChargeDate"
            label="Next Billed"
            :formatter="formatNextBillDate"
            sortable
          ></el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <router-link :to="`/subscription/${scope.row.id}`">
                <el-button type="text">View Subscription</el-button>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        Looks like you don't have any subscriptions yet.
        <router-link class="has-text-weight-bold" :to="{name: 'home'}">Check out the market.</router-link>
      </div>
    </div>
    <div v-else>Loading...</div>
  </div>
</template>
 
<script>
import { API, graphqlOperation } from 'aws-amplify'
import { getStripeSubscriptions } from '@/api/stripe.service'
import { timeFormat } from 'd3'
import Subscription from '@/models/Subscription'
import SubscriptionStatus from '@/components/SubscriptionStatus.vue'

const listSubs = `query ListSubscriptions(
  $filter: ModelSubscriptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listSubscriptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      stripeId
      owner
      createdAt
      plan {
        id
        stripeId
        owner
        allowance
        service {
          id
          title
          summary
          description
          archived
          owner
          stripeId
        }
      }
    }
  }
}
`

const dateTimeFormat = timeFormat('%_d %b %Y')
export default {
  name: 'CustomerSubscriptions',
  components: {
    SubscriptionStatus,
  },
  data() {
    return {
      subscriptions: null,
      loading: true,
    }
  },
  async created() {
    const res = await API.graphql(
      graphqlOperation(listSubs, {
        limit: 1000,
        filter: {
          owner: { eq: this.$store.state.user.username },
        },
      })
    )
    if (res.data.listSubscriptions.items.length) {
      const { data: stripeSubscriptions } = await getStripeSubscriptions()
      this.subscriptions = res.data.listSubscriptions.items
        .map((sub) => {
          const stripeSub = stripeSubscriptions.data.find((s) => s.id === sub.stripeId)
          if (stripeSub) {
            return new Subscription(sub, stripeSub)
          }
          return null
        })
        .filter((d) => d !== null)
    } else {
      this.subscriptions = []
    }
    this.loading = false
  },
  filters: {
    formatDate(value) {
      return dateTimeFormat(value)
    },
  },
  methods: {
    formatNextBillDate(row) {
      if (row.nextChargeDate) {
        return dateTimeFormat(row.nextChargeDate)
      }
      return '---'
    },
    formatName(row) {
      return row.plan.service.title
    },
    formatAmount(row) {
      const monetaryString = `$${(row.plan.amount / 100).toFixed(2)}`
      return `${monetaryString}/${row.plan.interval}`
    },
  },
}
</script>
