<template>
  <div>
    <section class="section">
      <div class="container">
        <h3 class="is-size-3 has-small-margin-bottom">Subscriptions</h3>
        <customer-subscriptions></customer-subscriptions>
      </div>
    </section>
    <section class="section has-padding-top" v-show="hasInvoices">
      <div class="container">
        <h3 class="is-size-3 has-small-margin-bottom">Invoices</h3>
        <customer-invoices @invoices="onInvoices"></customer-invoices>
      </div>
    </section>
  </div>
</template>

<script>
import CustomerSubscriptions from '@/components/CustomerSubscriptions.vue'
import CustomerInvoices from '@/components/CustomerInvoices.vue'

export default {
  name: 'Subscriptions',
  components: {
    CustomerSubscriptions,
    CustomerInvoices,
  },
  data() {
    return {
      hasInvoices: false,
    }
  },
  methods: {
    onInvoices(invoices) {
      console.log({ invoices })
      this.hasInvoices = invoices && invoices.length
    },
  },
}
</script>