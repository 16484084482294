class Plan {
  constructor(dbPlan, stripePlan) {
    this.id = dbPlan.id
    this.name = dbPlan.name || stripePlan.nickname
    this.description = dbPlan.description
    this.allowance = dbPlan.allowance
    this.stripe = stripePlan
    this.service = dbPlan.service
  }
  get isUnlimited() {
    return !this.allowance
  }
  get interval() {
    return this.stripe.interval
  }
  get intervalCount() {
    return this.stripe.interval_count
  }
  get amount() {
    return this.stripe.amount
  }
  get trialPeriodDays() {
    return this.stripe.trial_period_days
  }
  get active() {
    return this.stripe.active
  }
  get amountFormatted() {
    const monetaryString = `$${(this.amount / 100).toFixed(2)}`
    return `${monetaryString}/${this.interval}`
  }
  get allowanceFormatted() {
    if (this.allowance) {
      return `${this.allowance} credits`
    }
    return 'Unlimited'
  }
  get hasTrial() {
    return !!this.stripe.trial_period_days
  }
  get metadata() {
    return this.stripe.metadata
  }
}

export default Plan
